@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

body {
	margin: 0;
	font-family: "Jost", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #F7F7F9;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.MuiButton-root,
.MuiTypography-root,
.MuiFormLabel-root,
.MuiInputBase-input {
	font-family: "Jost", sans-serif !important;
}

.MuiInputBase-input {
	text-transform: uppercase !important;
	font-size: small !important;
}

.content {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	max-width: 1500px !important;
	/* text-align: -webkit-center */
}

.logo {
	text-align: -webkit-center;
	text-align: center;
}

.loginCard {
	width: 400px
}

.kycCapture {
	width: 700px
}

/* .ipvText {
	position: absolute;
	margin-top: -110px !important;
	margin-left: 80px !important;
	font-weight: 600 !important;
} */

.stepperCard {
	/* width: 1200px */
	width: 1500px !important;
	height: 700px !important;
}

.react-html5-camera-photo>img,
.react-html5-camera-photo>video {
	width: 380px !important;
}

/* .MuiTypography-root,
.MuiFormLabel-root {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif !important;
} */

.MuiAppBar-root {
	background-color: #FFF !important;
	/* color: transparent !important;
	box-shadow: none !important; */
}

.footer {
	position: fixed;
	/* height: 50px; */
	bottom: 0px;
	left: 0px;
	right: 0px;
	margin-bottom: 0px;
	/* background-color: rgb(230, 126, 126) !important; */
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.MuiMobileStepper-dots {
	display: none !important;
}

.Mui-error {
	margin-left: 0 !important;
}

.swiperHeight {
	height: 400px;
}

.form-control {
	display: block;
	width: 100%;
	padding: .5rem 1rem;
	font-size: 0.8rem;
	font-weight: 600;
	line-height: 1.49;
	color: var(--phoenix-body-color);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: var(--phoenix-emphasis-bg);
	background-clip: padding-box;
	border: var(--phoenix-border-width) solid var(--phoenix-border-color);
	border-radius: var(--phoenix-border-radius);
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0);
	-webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
	-o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.kycGrid {
	position: relative;
	width: 100%;
	text-align: center;
}

.ipvText {
	position: absolute;	
	left: 45%;
	top: 70%;
	transform: translate(-50%, -50%);
	padding: 0.5rem;
	text-align: center;	
	font-weight: 600;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.MuiBox-root {
		height: auto !important;
	}

	.MuiGrid-item {
		padding-right: 8px;
	}

	/* .content {
		width: auto !important;
	} */

	.loginCard {
		width: auto !important;
	}

	.paymentdetail .title {
		position: absolute;
	}

	.cardtemplate {
		background-color: #fff;
		color: rgba(0, 0, 0, 0.87);
		-webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		border-radius: 4px;
		box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
		/* overflow: hidden; */
		width: 100% !important;
		margin-left: 0 !important;
	}

	.react-html5-camera-photo>img,
	.react-html5-camera-photo>video {
		width: 100% !important;
	}

	.cameraBox {
		width: 100%;
		/* padding: 16px; */
		margin-top: 50px;
		margin-bottom: 50px
	}

	.signCls {
		padding: 0;
		margin-top: 70px;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}